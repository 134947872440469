import React from "react";
import Preview from "./preview";

export default function Home1() {
  return (
    <>
      <Preview></Preview>
    </>
  );
}
